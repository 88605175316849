import { Box, FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Chart, { Tooltip } from "chart.js";
import CustomSelect from "./CustomSelect";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
const Component = ({
  graphCategoryName,
  graphData,
  handleChangeSelect,
  handleChangeSelect1,
  onchangeCategoryId,
  CategoryId,
  value,
  val,
  val1,
  val2,
  toggleValue,
  holiday,
  setProductData,
  handleChangeSelect2,
  customerId,
  mobileOpen,
  holidayIndex,
  setHolidayIndex,
  empty,
  setCategoryId,
  setProductHoliday,
  setProductHoliday1,
  setHolidayName,
}) => {
  const classes = useStyles();
  const months = [
    { no: "1", name: "January" },
    { no: "2", name: "february" },
    { no: "3", name: "March" },
    { no: "4", name: "April" },
    { no: "5", name: "May" },
    { no: "6", name: "June" },
    { no: "7", name: "July" },
    { no: "8", name: "August" },
    { no: "9", name: "September" },
    { no: "10", name: "October" },
    { no: "11", name: "November" },
    { no: "12", name: "December" },
  ];
  const yearsSelected = ["2022", "2021", "2020", "2019", "2018", "2017"];
  const chartRef = useRef(null);
  let newNestedData;
  let newNestedName;
  useEffect(() => {
    var myChart;
    var holidaysSelect = [];
    holidaysSelect.push(Object.keys(holiday));
    let Quantity = graphData ? graphData.quantites : "";
    if (value === "amount") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      graphData = graphData?.product_sales_total;
      setProductData("");
    }
    if (toggleValue === "holiday" && value === "amount") {
      // graphData = Object.values(holiday)[holidayIndex]?.product_data?.product_sales_total;
      graphData = Object.values(holiday)[holidayIndex]?.product_data;
      const newNestedData = graphData ? Object.values(graphData) : "";
      const newNestedName = graphData ? Object.keys(graphData) : "";
      const newArray =
        newNestedData &&
        newNestedData?.map((item, index) => {
          item = newNestedData[index].product_sales_total;
          return item;
        });
      const newQuantity =
        newNestedData &&
        newNestedData?.map((item, index) => {
          item = newNestedData[index].quantities;
          return item;
        });
      graphData = newArray;
      Quantity = newQuantity;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      graphCategoryName = newNestedName;
    }
    if (value === "invoices") {
      graphData = graphData?.product_invoiced_sales_total;
      setProductData("");
    }
    if (toggleValue === "holiday" && value === "invoices") {
      graphData = Object.values(holiday)[holidayIndex]?.product_data;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      newNestedData = graphData ? Object.values(graphData) : "";
      // eslint-disable-next-line react-hooks/exhaustive-deps
      newNestedName = graphData ? Object.keys(graphData) : "";
      const newArray =
        newNestedData &&
        newNestedData?.map((item, index) => {
          item = newNestedData[index].product_invoiced_sales_total;
          return item;
        });
      const newQuantity =
        newNestedData &&
        newNestedData?.map((item, index) => {
          item = newNestedData[index].quantities;
          return item;
        });
      graphData = newArray;
      Quantity = newQuantity;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      graphCategoryName = newNestedName;
    }

    // Create the chart context

    const canvas = document.getElementById("myChart");
    const ctx = canvas.getContext("2d");
    const plugin2 = {
      beforeInit: function (chart) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Change the height as suggested in another answers
          this.height += 15;
        };
      },
    };
    const yAxisLabelCallback = (value1) => {
      if (value1 >= 1000000) {
        return (value1 / 1000000).toFixed(1) + "M€";
      } else if (value1 >= 1000) {
        return (value1 / 1000).toFixed(1) + "k€";
      } else {
        return value1;
      }
    };

    const xAxisLabelCallback = (name) => {
      if (name.length > 7) {
        return name.substring(0, 7 - 2) + "..."; // Add ellipsis
      } else return name;
    };

    const emptyFunction = (value1) => {
      return value1;
    };

    const data = {
      labels: toggleValue === "holiday" ? graphCategoryName : graphCategoryName?.product_category_name,
      datasets: [
        // {
        //   label: ` Category ${value} `,
        //   data: graphData,
        //   backgroundColor: "#3AA6B9",
        // },
        {
          label: ` Category ${value} `,
          backgroundColor: "#3AA6B9",
          // borderColor: 'rgba(75, 192, 192, 1)',
          yAxisID: "y-axis-1",
          borderWidth: 1,
          data: graphData,
        },
        {
          label: "Category Quantity",
          backgroundColor: "rgba(255, 99, 132, 0.6)",
          borderColor: "rgba(255, 99, 132, 1)",
          yAxisID: "y-axis-2",

          // borderWidth: 1,
          data: Quantity,
        },
      ],
    };

    const options = {
      // responsive: true,
      legend: {
        onClick: () => false,
        label: {
          padding: 50,
        },
      },
      interaction: {
        mode: "index",
      },
      showTooltips: true,
      scales: {
        yAxes: [
          {
            id: "y-axis-1",
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              callback: graphData ? yAxisLabelCallback : emptyFunction,
            },
          },
          {
            id: "y-axis-2",
            // type: 'linear', // Use linear scale for dataset2
            position: "right",
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              // callback: graphData ? yAxisLabelCallback : emptyFunction,
            },
          },
        ],
        xAxes: [
          {
            display: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 20,
              callback: xAxisLabelCallback,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          align: "end",
          anchor: "end",
          rotation: 0,
          formatter: yAxisLabelCallback,
          font: {
            weight: "bold",
          },
        },
      },
    };

    // Create the bar chart
    if (!empty) {
      myChart = new Chart(ctx, {
        type: "bar",
        data: data,
        options: options,
        plugins: [ChartDataLabels, plugin2],
      });
      return () => {
        myChart.destroy();
      };
    }
  }, [graphCategoryName, graphData, value, holidayIndex, holiday, empty]);

  let result;
  if (toggleValue === "holiday") {
    graphData = Object.values(holiday)[holidayIndex]?.product_data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    newNestedData = graphData ? Object.values(graphData) : "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
    newNestedName = graphData ? Object.keys(graphData) : "";
    console.log("category name>>>>>>>>>>>>>>>", newNestedName);
    result =
      newNestedName &&
      newNestedName?.map((name, index) => {
        return {
          name: name,
          id: newNestedData[index]?.product_internal_category_id,
        };
      });
  } else {
    result = graphCategoryName?.product_category_name?.map((name, index) => {
      return {
        name: name,
        id: graphCategoryName.product_internal_category_id[index],
      };
    });
  }

  console.log("result>>>>>>>>>>>>>>>", result);
  const handleChangeSelectHoliday = (e, f) => {
    setCategoryId("initial");
    setHolidayIndex(e.target.value);
    setHolidayName(f.props.children);
    setProductHoliday("");
    setProductHoliday1("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: "100%", lg: "35%" },
        // bgcolor: "red",
      }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomSelect
          defaultValue={"Filter by year"}
          menuItemData={yearsSelected}
          initialValue={"2023"}
          handleChange={handleChangeSelect}
          disable={customerId === "" ? true : false}
          mW={140}
          value={val}
        />
        {toggleValue === "holiday" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <FormLabel
              sx={{
                pl: 1,
                fontSize: "14px",
              }}>
              Filter by Holidays
            </FormLabel>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                SelectDisplayProps={{
                  style: {
                    padding: 5,
                    marginLeft: "10px",
                    fontSize: "12px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100px",
                  },
                }}
                disabled={customerId === "" ? true : false}
                MenuProps={{ disableScrollLock: true }}
                inputProps={{
                  style: {
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontSize: "12px",
                  },
                }}
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  borderBottom: "none",
                  color: "#dddddd",
                  width: "140px",
                }}
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={holidayIndex}
                onChange={handleChangeSelectHoliday}>
                <MenuItem value="initial">
                  <em>{`Select `} </em>
                </MenuItem>
                {Object.keys(holiday)?.map((item, index) => (
                  <MenuItem key={index} value={(item, index)}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}>
              <FormLabel
                sx={{
                  pl: 1,
                  fontSize: "14px",
                }}>
                Filter by month
              </FormLabel>
              <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
                <Select
                  SelectDisplayProps={{
                    style: {
                      padding: 5,
                      marginLeft: "10px",
                      fontSize: "12px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100px",
                    },
                  }}
                  disabled={customerId === "" ? true : false}
                  MenuProps={{ disableScrollLock: true }}
                  inputProps={{
                    style: {
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      fontSize: "12px",
                    },
                  }}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    borderBottom: "none",
                    color: "#dddddd",
                  }}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={val1}
                  onChange={handleChangeSelect1}>
                  <MenuItem value="initial">
                    <em>{`Select a month`} </em>
                  </MenuItem>
                  {months.map((item, index) => (
                    <MenuItem key={index} value={item.no}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <CustomSelect
              defaultValue={"Filter by week"}
              disable={customerId === "" ? true : false}
              menuItemData={["1", "2", "3", "4", "5"]}
              initialValue={"Select a week"}
              handleChange={handleChangeSelect2}
              mW={140}
              value={val2}
            />
          </>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}>
          <FormLabel
            sx={{
              pl: 1,
              fontSize: "14px",
            }}>
            {"Filter By Category"}
          </FormLabel>
          <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
            <Select
              SelectDisplayProps={{
                style: {
                  padding: 5,
                  marginLeft: "10px",
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100px",
                },
              }}
              disabled={customerId === "" ? true : false}
              MenuProps={{ disableScrollLock: true }}
              inputProps={{
                style: {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100px",
                },
              }}
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                borderBottom: "none",
                color: "#dddddd",
              }}
              value={CategoryId}
              onChange={onchangeCategoryId}>
              <MenuItem value="initial">
                <em>{`Select a category`} </em>
              </MenuItem>
              {result &&
                result?.map((item, index) => (
                  <MenuItem key={index} className={classes.ellipsis} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box
        sx={{
          // ml: 10,
          mt: 5,
          width: mobileOpen ? "600px" : "650px",
          height: "300px",
        }}>
        <canvas width={"70%"} height={"40%"} id="myChart"></canvas>
      </Box>
    </Box>
  );
};

export default Component;
