import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const ControlledRadioButtonsGroup = ({ handleChange, value }) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        sx={{ display: "flex", flexDirection: "row", ml: "60px" }}
        onChange={handleChange}>
        <FormControlLabel value="amount" control={<Radio />} label="Amount" />
        <FormControlLabel value="invoices" control={<Radio />} label="Invoices" />
      </RadioGroup>
    </FormControl>
  );
};
export default ControlledRadioButtonsGroup;
