import { Api } from "../../../config/request";
export const requestCustomerdata = async (data, page, perPage) => {
  return Api.PostRequest(`customers-data.php?page=${page}&perPage=${perPage}`, data);
};
export const requestCustomerData = async (data, page, perPage) => {
  return Api.PostRequest(`customers-api.php?page=${page}&perPage=${perPage}`, data);
};
export const requestCategoryGraphData = async (data, id, year, month, week) => {
  return Api.PostRequest(`category-graph-data.php?clientid=${id}&year=${year}&month=${month}&week=${week}`, data);
};
export const requestgetIncCategoryGraphData = async (data, id, year, month, week) => {
  return Api.PostRequest(`category-predictive-graph.php?clientid=${id}&year=${year}&month=${month}&week=${week}`, data);
};
export const requestProductGraphData = async (data, id, id2, year, month, week, sort) => {
  return Api.PostRequest(
    `product-graph-data.php?categoryid=${id}&clientid=${id2}&year=${year}&month=${month}&sort=${sort}&week=${week}`,
    data
  );
};
export const requestIncProductGraphData = async (data, id, id2, year, month, week, sort) => {
  return Api.PostRequest(
    `product-predictive-graph.php?categoryid=${id}&clientid=${id2}&year=${year}&month=${month}&sort=${sort}&week=${week}`,
    data
  );
};
export const requestCustomersBySearch = async (data) => {
  return Api.PostRequest(`search.php?search=${data}`);
};
export const requestHolidayCategoryGraphData = async (data, id, year, month) => {
  return Api.PostRequest(`holidays-api.php?clientid=${id}&year=${year}`, data);
};
export const requestHolidayProductGraphData = async (data, id, id2, year, month, week, sort, holidayName) => {
  return Api.PostRequest(
    `holidays-product-api.php?clientid=${id}&categoryid=${id2}&holidayname=${holidayName}&year=${year}&sort=${sort}`,
    data
  );
};
export const requestHolidayPredictiveGraphData = async (data, id, year, week, month) => {
  return Api.PostRequest(`predictive-holidays-category-api.php?clientid=${id}&year=${year}`, data);
};
export const requestHolidayPredictiveProductData = async (data, id, id2, year, month, week, sort, holidayName) => {
  return Api.PostRequest(
    `predictive-holidays-product-api.php?clientid=${id}&categoryid=${id2}&holidayName=${holidayName}&year=${year}&sort=${sort}`,
    data
  );
};

export const requestCustomerGrowth = async (data) => {
  return Api.PostRequest(`customer_api.php?clientid=${data}`, data);
};
export const requestCustomerGrowthbyYear = async (data, client_id, year, month) => {
  return Api.PostRequest(`customer_api_week.php?clientid=${client_id}&year=${year}&month=${month}`, data);
};
export const requestCustomerGrowthHolidaysData = async (data, client_id, year, month) => {
  return Api.PostRequest(`customer_api_holidays.php?clientid=${client_id}&year=${year}&month=${month}`, data);
};
export const requestCustomerGrowthbyYearMonth = async (data, client_id, year) => {
  return Api.PostRequest(`customer_api_month.php?clientid=${client_id}&year=${year}`, data);
};
export const requestCategoryGrowth = async (data, client_id) => {
  return Api.PostRequest(`product_category.php?clientid=${client_id}`, data);
};
export const requestCategoryAllGrowth = async (data, client_id, id2) => {
  return Api.PostRequest(`product_category_growth.php?clientid=${client_id}&product_internal_category_id=${id2}`, data);
};
export const requestCategoryYearGrowth = async (data, client_id, id2, year) => {
  return Api.PostRequest(`product_category_month_growth.php?clientid=${client_id}&product_internal_category_id=${id2}&year=${year}`, data);
};
export const requestCategoryMonthGrowth = async (data, client_id, id2, year, month) => {
  return Api.PostRequest(
    `product_category_growth_week.php?clientid=${client_id}&product_internal_category_id=${id2}&year=${year}&month=${month}`,
    data
  );
};
export const requestComparisionData = async (client_id, id2) => {
  return Api.PostRequest(
    `client_comparison.php?clientid1=${client_id}&clientid2=${id2}`
  );
};
const CustomerApi = {
  requestCustomerdata,
  requestCustomerData,
  requestCategoryGraphData,
  requestProductGraphData,
  requestCustomersBySearch,
  requestHolidayCategoryGraphData,
  requestHolidayProductGraphData,
  requestgetIncCategoryGraphData,
  requestIncProductGraphData,
  requestHolidayPredictiveGraphData,
  requestHolidayPredictiveProductData,
  requestCustomerGrowth,
  requestCustomerGrowthbyYear,
  requestCustomerGrowthHolidaysData,
  requestCustomerGrowthbyYearMonth,
  requestCategoryGrowth,
  requestCategoryAllGrowth,
  requestCategoryYearGrowth,
  requestCategoryMonthGrowth,
  requestComparisionData
};
export default CustomerApi;
