/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import { connect } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import ListIcon from "@mui/icons-material/List";
import Component1 from "./Component1";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import SearchInput from "./CustomSearch";
import Component2 from "./Component2";
import Component3 from "./Component3";
import CustomSelect from "./CustomSelect";
// import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import WorkIcon from "@mui/icons-material/Work";
import InfiniteScroll from "react-infinite-scroll-component";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import Toastify from "./SnackBar/Toastify";
import {
  getCustomerCategoryGraphData,
  getCustomerProductGraphData,
  getCustomerListingdata,
  getCustomerOffCategoryData,
  getCustomerOFFProductGraphData,
  getIncCustomerCategoryGraphData,
  getIncCustomerProductGraphData,
  getCustomerPredictiveOffCategoryData,
  getCustomerPredictiveOffProductData,
  getCustomerGrowth,
  filterCustomerGrowth,
  getCustomerGrowthHolidaysData,
  filterCustomerGrowthbyMonth,
  getCategoriesForGrowth,
  getCategoryGrowth,
  getCategoryGrowthByYear,
  getCategoryGrowthByMonth,
  getComparisionData,
} from "../redux/action/customers/index";
import ControlledRadioButtonsGroup from "./RadioButton";
import Component from "./Component";
import LoaderComponent from "./LoaderComponent";
import MuiToggleButton from "@mui/material/ToggleButton";
import Component4 from "./Component4";
import Component5 from "./Component5";
import Component6 from "./Component6";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const drawerWidth = 250;
const yearsSelected = ["2022", "2021", "2020", "2019", "2018", "2017"];
const menuList = [
  { id: "working", name: "Working days Data" },
  { id: "holiday", name: "Holiday Data" },
  { id: "growth", name: "Customer Growth  Data" },
  { id: "category_growth", name: "Product Category Growth" },
  { id: "comparision", name: "Comparision" },
];

// Toggle Button design

const ToggleButton = styled(MuiToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#red",
  },
});

const useStyles = makeStyles(() => ({
  textOverflow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&.MuiTypography-root": {
      fontSize: "0.8rem",
      fontWeight: "550",
    },
  },
  listItemText: {
    fontSize: "0.7em",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.2em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 3px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

// Render Component
const DrawerWithFirstGraph = ({
  getCustomerListingdata,
  getCustomerCategoryGraphData,
  getCustomerProductGraphData,
  getCustomerOffCategoryData,
  getCustomerOFFProductGraphData,
  getIncCustomerCategoryGraphData,
  getIncCustomerProductGraphData,
  getCustomerPredictiveOffCategoryData,
  getCustomerPredictiveOffProductData,
  getCustomerGrowth,
  filterCustomerGrowth,
  getCustomerGrowthHolidaysData,
  filterCustomerGrowthbyMonth,
  getCategoriesForGrowth,
  getCategoryGrowth,
  getCategoryGrowthByYear,
  getCategoryGrowthByMonth,
}) => {
  const classes = useStyles();

  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const [customerId, setCustomerId] = React.useState("");
  const [CategoryId, setCategoryId] = React.useState("initial");
  const [graphData, setGraphData] = React.useState("");
  const [graphData1, setGraphData1] = React.useState("");
  const [graphCategoryName, setGraphCategoryName] = React.useState("");
  const [graphCategoryName1, setGraphCategoryName1] = React.useState("");
  const [productData, setProductData] = React.useState("");
  const [productQuantity, setProductQuantity] = React.useState("");
  const [productData1, setProductData1] = React.useState("");
  const [productHoliday, setProductHoliday] = React.useState("");
  const [productHoliday1, setProductHoliday1] = React.useState("");
  const [productName, setProductName] = React.useState("");
  const [productName1, setProductName1] = React.useState("");
  const [holiday, setHoliday] = useState("");
  const [holiday1, setHoliday1] = useState("");
  const [holidayIndex, setHolidayIndex] = React.useState("initial");
  const [holidayName, setHolidayName] = React.useState("");
  const [year, setyear] = React.useState("initial");
  const [week, setweek] = React.useState("initial");
  const [empty, setEmpty] = React.useState(false);
  const [sort, setsort] = React.useState("initial");
  const [month, setMonth] = React.useState("initial");
  const [searched, setSearched] = React.useState(false);
  const [toggleValue, setToggleValue] = useState("working");
  const [loader, setLoader] = useState(false);
  const [buttonBgColor, setButtonBgColor] = useState("#567189");
  const [customerGrowthPayloads, setCustomerGrowthPayloads] = useState({
    year: "initial",
    month: "initial",
  });
  const [value, setValue] = React.useState(
    toggleValue === "comparision" ? "Client 1" : "amount"
  );
  const [customerGrowthHolidays, setCustomerGrowthHolidays] = useState("");
  const [categories, setCategories] = useState("");
  const [client1, setClient1] = useState("");
  const [client2, setClient2] = useState("");
  const [clientname, setClientname] = useState({ client1: "", client2: "" });
  console.log("loaderStatus", loader);
  useEffect(() => {
    let data1;
    // API call to get Customers List
    if (!searched) {
      setLoader(true);

      getCustomerListingdata(data1, pagination.page, pagination.perPage).then(
        (res) => {
          if (res.data) {
            setLoader(false);
            setCustomers(res.data);
          }
        }
      );
    }
  }, [
    // pagination,
    // year,
    // customerId,
    // productName,
    // graphData,
    // value,
    // month,
    // CategoryId,
    // buttonBgColor,
    // holiday,
    // toggleValue,
    // empty,
    // week,
    // holidayIndex,
    // productHoliday,
    // holidayName,
    searched,
    clientname,
    client1,
    client2,
    // customerGrowthPayloads,
  ]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChangeRadioButton = (event) => {
    setValue(event.target.value);
    setCategoryId("initial");
  };

  const handleToggleChange = (e) => {
    console.log(e.target.value);
    setToggleValue(e.target.value);
    setButtonBgColor(e.target.value === "holiday" ? "#021d36" : "red");
    setCategoryId("initial");
    setyear("initial");
    setMonth("initial");
    if (e.target.value === "holiday") {
      setGraphData("");
      setGraphData1("");
      setCustomerId("");
      setGraphCategoryName("");
      setGraphCategoryName1("");
      setProductData("");
      setProductQuantity("");
      setProductData1("");
      setProductName("");
      setProductName("");
      setValue("amount");
      setClient1("");
      setClient2("");
      setClientname({ client1: "", client2: "" });
    } else if (e.target.value === "growth") {
      setGraphData("");
      setGraphData1("");
      setCustomerId("");
      setGraphCategoryName("");
      setGraphCategoryName1("");
      setProductData("");
      setProductQuantity("");

      setProductData1("");
      setProductName("");
      setCustomerGrowthPayloads({ year: "initial", month: "initial" });
      setProductName("");
      setValue("amount");
      setHoliday("");
      setHoliday1("");
      setClient1("");
      setClient2("");
      setClientname({ client1: "", client2: "" });
    } else if (e.target.value === "working") {
      setGraphData("");
      setGraphData1("");
      setCustomerId("");
      setGraphCategoryName("");
      setGraphCategoryName1("");
      setProductData("");
      setProductQuantity("");
      setProductHoliday("");
      setProductData1("");
      setProductName("");
      setCustomerGrowthPayloads({ year: "initial", month: "initial" });
      setProductName("");
      setValue("amount");
      setHoliday("");
      setHoliday1("");
      setClient1("");
      setClient2("");
      setClientname({ client1: "", client2: "" });
    } else if (e.target.value === "category_growth") {
      setGraphData("");
      setGraphData1("");
      setCustomerId("");
      setGraphCategoryName("");
      setGraphCategoryName1("");
      setProductData("");
      setProductQuantity("");

      setProductData1("");
      setProductName("");
      setCustomerGrowthPayloads({ year: "initial", month: "initial" });
      setProductName("");
      setValue("amount");
      setHoliday("");
      setHoliday1("");
      setClient1("");
      setClient2("");
      setClientname({ client1: "", client2: "" });
    } else if (e.target.value === "comparision") {
      setGraphData("");
      setGraphData1("");
      setCustomerId("");
      setGraphCategoryName("");
      setGraphCategoryName1("");
      setProductData("");
      setProductQuantity("");
      setProductData1("");
      setProductName("");
      setCustomerGrowthPayloads({ year: "initial", month: "initial" });
      setProductName("");
      setValue("");
      setHoliday("");
      setHoliday1("");
    }
  };

  // To load more customers list
  const loadMore = () => {
    let data1;
    setPagination({ page: pagination.page, perPage: pagination.perPage + 20 });
    getCustomerListingdata(data1, pagination.page, pagination.perPage).then(
      (res) => {
        setCustomers(res.data);
      }
    );
  };

  // Function to set graph values
  const handleCategoryGraph = (
    id,
    index,
    yearSelected,
    monthSelected,
    weekSelected
  ) => {
    let updateId;
    setLoader(true);
    updateId = id;
    let data;
    console.log("loader", loader);
    if (toggleValue === "holiday") {
      // For getting holiday data
      getCustomerOffCategoryData(
        data,
        updateId,
        yearSelected !== "" ? yearSelected : 2023,
        monthSelected
          ? monthSelected
          : monthSelected === ""
          ? monthSelected
          : month === "initial"
          ? ""
          : month,
        weekSelected
          ? weekSelected
          : weekSelected === ""
          ? weekSelected
          : week === "initial"
          ? ""
          : week
      ).then((res) => {
        if (res?.data) {
          setLoader(false);
          setHoliday(res?.data);
          setEmpty(false);
          setCategoryId("initial");
        } else {
          setEmpty(true);
        }
      });
      getCustomerPredictiveOffCategoryData(
        data,
        updateId,
        yearSelected !== "" ? yearSelected : 2023,
        monthSelected
          ? monthSelected
          : monthSelected === ""
          ? monthSelected
          : month === "initial"
          ? ""
          : month,
        weekSelected
          ? weekSelected
          : weekSelected === ""
          ? weekSelected
          : week === "initial"
          ? ""
          : week
      ).then((res) => {
        if (res?.data) {
          setLoader(false);

          setHoliday1(res?.data);
          setEmpty(false);
          setCategoryId("initial");
        } else {
          setEmpty(true);
        }
      });
    } else if (toggleValue === "growth") {
      getCustomerGrowth(updateId).then((res) => {
        setLoader(false);

        setGraphData(res?.data?.year_data?.percentage_growth);
        setGraphCategoryName(res?.data?.year_data?.years);
      });
      setHoliday("");
      setHoliday1("");
      setGraphData1("");
      setGraphCategoryName1("");
    } else if (toggleValue === "category_growth") {
      getCategoriesForGrowth(data, updateId).then((res) => {
        setLoader(false);

        setCategories(res.data);
      });
      setHoliday("");
      setHoliday1("");
      setGraphData1("");
      setGraphCategoryName1("");
    } else if (toggleValue === "comparision") {
      setLoader(false);
      if (value === "Client_1") {
        setClient1(updateId);
        customers &&
          customers?.map(
            (text, index) =>
              updateId === text.client_id &&
              setClientname({
                client1: text.customer_business_entity_name,
                client2: clientname.client2,
              })
          );
      } else {
        setClient2(updateId);
        customers &&
          customers?.map(
            (text, index) =>
              updateId === text.client_id &&
              setClientname({
                client1: clientname.client1,
                client2: text.customer_business_entity_name,
              })
          );
      }
    } else {
      getCustomerCategoryGraphData(
        // For getting working days data
        data,
        updateId,
        yearSelected,
        monthSelected
          ? monthSelected
          : monthSelected === ""
          ? monthSelected
          : month === "initial"
          ? ""
          : month,
        weekSelected
          ? weekSelected
          : weekSelected === ""
          ? weekSelected
          : week === "initial"
          ? ""
          : week
      ).then((res) => {
        setLoader(false);

        if (res?.data?.product_sales_total?.length !== 0) {
          setGraphData(res.data);
          setGraphCategoryName(res.data);
          setCategoryId("initial");
          //
        } else {
          setGraphData("");
          setGraphCategoryName("");
        }
      });
      getIncCustomerCategoryGraphData(
        data,
        updateId,
        yearSelected,
        monthSelected
          ? monthSelected
          : monthSelected === ""
          ? monthSelected
          : month === "initial"
          ? ""
          : month,
        weekSelected
          ? weekSelected
          : weekSelected === ""
          ? weekSelected
          : week === "initial"
          ? ""
          : week
      ).then((res) => {
        if (res?.data?.product_sales_total_predictive?.length !== 0) {
          setGraphData1(res.data);
          setGraphCategoryName1(res.data);
          setCategoryId("initial");
          //
        } else {
          setGraphData1("");
          setGraphCategoryName1("");
        }
      });
    }
  };
  console.log(clientname);
  // Function to handle year change
  const handleChangeSelect = (event) => {
    setyear(event.target.value);
    setProductData1("");
    setProductQuantity("");
    setProductData("");
    setProductName("");
    setProductName1("");
    setHolidayIndex("initial");
    console.log(event.target.value);
    handleCategoryGraph(
      customerId,
      "",
      event.target.value === "initial" ? 2023 : event.target.value,
      month === "initial" ? "" : month,
      week === "initial" ? "" : week
    );
  };

  // Function to handle month change
  const handleChangeSelect1 = (event) => {
    setProductData("");
    setProductData1("");
    setProductQuantity("");
    setProductName("");
    setProductName1("");
    setHolidayIndex("initial");
    setMonth(event.target.value);
    handleCategoryGraph(
      customerId,
      "",
      year === "initial" ? 2023 : year,
      event.target.value === "initial" ? "" : event.target.value,
      week === "initial" ? "" : week
    );
  };

  // Function to handle week change
  const handleChangeSelect2 = (event) => {
    setweek(event.target.value);
    setProductData1("");
    setProductData("");
    setProductQuantity("");
    setProductName("");
    setProductName1("");
    handleCategoryGraph(
      customerId,
      "",
      year === "initial" ? 2023 : year,
      month === "initial" ? "" : month,
      event.target.value === "initial" ? "" : event.target.value
    );
  };

  // Function to handle sort value change
  const handleSortChangeSelect = (event) => {
    setLoader(true);
    setsort(event.target.value);
    if (CategoryId) {
      if (toggleValue === "holiday") {
        getCustomerOFFProductGraphData(
          "",
          customerId,
          CategoryId,
          year === "initial" ? 2023 : year,
          month === "initial" ? "" : month,
          week === "initial" ? "" : week,
          event.target.value === "initial" ? "" : event.target.value,
          holidayName
        ).then((res) => {
          setLoader(false);
          setProductHoliday(res.data);
        });
        getCustomerPredictiveOffProductData(
          "",
          customerId,
          CategoryId,
          year === "initial" ? 2023 : year,
          month === "initial" ? "" : month,
          week === "initial" ? "" : week,
          event.target.value
        ).then((res) => {
          if (res.data) {
            setProductHoliday1(res.data);
            setEmpty(false);
          } else {
            setEmpty(true);
          }
        });
      } else {
        getCustomerProductGraphData(
          "",
          CategoryId,
          customerId,
          year === "initial" ? "" : year,
          month === "initial" ? "" : month,
          week === "initial" ? "" : week,
          event.target.value
        ).then((res) => {
          setLoader(false);
          console.log("res>>>>>>=====", res.data);
          setProductData(res.data.product_sales);
          setProductName(res.data.product_name);
        });
        getIncCustomerProductGraphData(
          "",
          CategoryId,
          customerId,
          year === "initial" ? "" : year,
          month === "initial" ? "" : month,
          week === "initial" ? "" : week,
          event.target.value
        ).then((res) => {
          setLoader(false);
          setProductData1(res.data.predictive_product_sales);
          setProductName1(res.data.product_name);
        });
      }
    }
  };

  // Function to handle category change
  const onchangeCategoryId = (e) => {
    let data = "";
    let id = e.target.value;
    setCategoryId(e.target.value);
    setLoader(true);
    if (toggleValue === "holiday") {
      getCustomerOFFProductGraphData(
        "",
        customerId,
        id,
        year === "initial" ? 2023 : year,
        week === "initial" ? "" : week,

        month === "initial" ? "" : month,
        sort,
        holidayName
      ).then((res) => {
        setLoader(false);
        setProductHoliday(res.data);
      });
      getCustomerPredictiveOffProductData(
        "",
        customerId,
        id,
        year === "initial" ? 2023 : year,
        month === "initial" ? "" : month,
        week === "initial" ? "" : week,
        sort
      ).then((res) => {
        if (res.data) {
          setProductHoliday1(res.data);
          setEmpty(false);
        } else {
          setEmpty(true);
        }
      });
    } else {
      getCustomerProductGraphData(
        data,
        id,
        customerId,
        year === "initial" ? 2023 : year,
        month === "initial" ? "" : month,
        week === "initial" ? "" : week
      ).then((res) => {
        setLoader(false);
        console.log("res>>>>>>=====", res.data);
        setProductData(res.data.product_sales);
        setProductName(res.data.product_name);
        setProductQuantity(res.data.quantities);
      });
      getIncCustomerProductGraphData(
        data,
        id,
        customerId,
        year === "initial" ? 2023 : year,
        month === "initial" ? "" : month,
        week === "initial" ? "" : week
      ).then((res) => {
        setLoader(false);
        setProductData1(res.data.predictive_product_sales);
        setProductName1(res.data.product_name);
      });
    }
    setsort("initial");
  };
  //function to handle year filter in customer growth
  const handleChangeSelectForGrowthYear = (e) => {
    setLoader(true);
    let data;
    setCustomerGrowthPayloads({
      year: e.target.value,
      month: customerGrowthPayloads.month,
    });
    filterCustomerGrowthbyMonth(
      data,
      customerId,
      e.target.value === "initial" ? 2023 : e.target.value
    ).then((res) => {
      // console.log(res);
      setLoader(false);
      setGraphData(res?.data?.percentage_growth);
      setGraphCategoryName(res?.data?.months);
    });
  };
  //function to handle month filter in customer growth
  const handleChangeSelectForGrowthMonth = (e) => {
    setLoader(true);
    setCustomerGrowthPayloads((prevState) => ({
      ...prevState,
      month: e.target.value === "initial" ? "" : e.target.value,
    }));
    let data;
    filterCustomerGrowth(
      data,
      customerId,
      customerGrowthPayloads.year === "initial"
        ? 2023
        : customerGrowthPayloads.year,
      e.target.value === "initial" ? "" : e.target.value
    ).then((res) => {
      setLoader(false);
      setGraphData(res?.data?.percentage_growth);
      setGraphCategoryName(res?.data?.weeks);
    });
    getCustomerGrowthHolidaysData(
      customerGrowthPayloads,
      customerId,
      customerGrowthPayloads.year,
      e.target.value === "initial" ? "" : e.target.value
    ).then((res) => {
      setCustomerGrowthHolidays(res.data.weeks);
    });
  };
  //function to handle category growth listing
  const onchangeCategoryIdForGrowth = (e) => {
    setCategoryId(e.target.value);
    customerCategoryGrowth(e.target.value);
  };
  //function to handle category growth graph
  const customerCategoryGrowth = (id2) => {
    setLoader(true);
    let data;
    setyear("initial");
    setMonth("initial");
    getCategoryGrowth(data, customerId, id2).then((res) => {
      setLoader(false);
      setGraphCategoryName(res.data.data.year);
      setGraphData(res.data.data.growth);
    });
  };
  //function to handle category growth year graph
  const handleChangeForCategoryGrowthYear = (e) => {
    setLoader(true);
    let data;
    setyear(e.target.value);
    getCategoryGrowthByYear(data, customerId, CategoryId, e.target.value).then(
      (res) => {
        setLoader(false);
        setGraphCategoryName(res.data.months);
        setGraphData(res.data.percentage_growth);
      }
    );
  };
  //function to handle category growth month graph
  const handleChangeForCategoryGrowthMonth = (e) => {
    setLoader(true);
    let data;
    setMonth(e.target.value);
    getCategoryGrowthByMonth(
      data,
      customerId,
      CategoryId,
      year,
      e.target.value
    ).then((res) => {
      setLoader(false);
      setGraphCategoryName(res.data.weeks);
      setGraphData(res.data.growth);
    });
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <LoaderComponent open={loader} />
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: mobileOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
            ml: mobileOpen ? "auto" : "",
            backgroundColor: "#567189",
          }}
        >
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ListIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setMobileOpen(!mobileOpen)}
                  sx={{ mr: "20px" }}
                />

                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                {customers &&
                  !toggleValue === "comparision" &&
                  customers?.map(
                    (text, index) =>
                      customerId === text.client_id && (
                        <Typography
                          key={index}
                          variant="h6"
                          noWrap
                          component="div"
                        >
                          {text.customer_business_entity_name}[{text.client_id}]
                        </Typography>
                      )
                  )}
                {toggleValue === "comparision" &&
                  clientname.client1 &&
                  clientname.client2 && (
                    <>
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                          maxWidth: "300px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {clientname?.client1}
                      </Typography>
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                          maxWidth: "300px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {" , " + clientname?.client2}
                      </Typography>
                    </>
                  )}
              </Box>
              <Box sx={{ display: "flex" }}>
                {/* ... rest of the code ... */}
                <Select
                  SelectDisplayProps={{
                    style: {
                      padding: 5,
                      marginLeft: "10px",
                      fontSize: "12px",
                      overflow: "hidden",
                    },
                  }}
                  onChange={handleToggleChange}
                  value={toggleValue}
                  inputProps={{
                    style: {
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      fontSize: "12px",
                    },
                  }}
                  sx={{ width: "200px", border: "none", bgcolor: "white" }}
                >
                  {menuList &&
                    menuList.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            minWidth: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
            ml: "auto",
            mr: "auto",
          }}
          variant="persistent"
          anchor="left"
          open={mobileOpen}
        >
          <div>
            <SearchInput
              setCustomers={setCustomers}
              searched={searched}
              setSearched={setSearched}
              setLoader={setLoader}
            />
            <ListItem sx={{ mt: "50px" }}>
              {/* <ListItemButton disableRipple disableTouchRipple> */}
              <ListItemText
                sx={{ ml: "10px", mt: "5px", mb: "-5px" }}
                primary={
                  customers?.length === 0 ? "No Result found" : "All Customers"
                }
              />
              {/* </ListItemButton> */}
            </ListItem>

            <List>
              {customers &&
                customers?.map((text, index) => (
                  <ListItem
                    sx={{
                      bgcolor:
                        customerId === text.client_id ||
                        client1 === text.client_id ||
                        client2 === text.client_id
                          ? "#567189"
                          : "",
                      color:
                        customerId === text.client_id ||
                        client1 === text.client_id ||
                        client2 === text.client_id
                          ? "#fff"
                          : "#000",
                      fontSize: "12px",
                    }}
                    key={index}
                    disablePadding
                  >
                    <Tooltip title={text.customer_business_entity_name}>
                      <ListItemButton
                        sx={{ pt: "5px", pb: "5px" }}
                        disabled={
                          toggleValue === "comparision" && value === ""
                            ? true
                            : false
                        }
                        onClick={() => {
                          setCustomerId(
                            toggleValue === "comparision" ? "" : text.client_id
                          );
                          handleCategoryGraph(
                            text.client_id,
                            index,
                            2023,
                            "",
                            ""
                          );
                          setCustomerGrowthPayloads({
                            year: "initial",
                            month: "initial",
                          });
                          setGraphData("");
                          setGraphData1("");
                          setGraphCategoryName("");
                          setGraphCategoryName1("");
                          setyear("initial");
                          setweek("initial");
                          setMonth("initial");
                          setsort("initial");
                          setProductQuantity("");
                          setProductData("");
                          setProductData1("");
                          setProductName("");
                          setProductName1("");
                          setHoliday("");
                          setProductHoliday("");
                          setHoliday1("");
                          setProductHoliday1("");
                          setHolidayIndex("initial");
                          setCategoryId("initial");
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: "35px" }}>
                          {
                            <PersonIcon
                              sx={{
                                color:
                                  customerId === text.client_id
                                    ? "#fff"
                                    : "#000",
                              }}
                            />
                          }
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.textOverflow,
                            secondary: classes.textOverflow,
                          }}
                          sx={{
                            width: "300px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            fontSize: "14px",
                            "&.MuiTypography-root": {
                              fontSize: "14px",
                            },
                          }}
                          primary={text.customer_business_entity_name}
                        />
                        <ListItemText
                          classes={{
                            primary: classes.textOverflow,
                            secondary: classes.textOverflow,
                          }}
                          sx={{
                            width: "200px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            fontSize: "14px",
                          }}
                          primary={`[Id:${text.client_id}]`}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ))}
            </List>

            {!searched && (
              <Button
                sx={{ textAlign: "center", width: "100%" }}
                onClick={() => loadMore()}
              >
                Load More
              </Button>
            )}
            <Divider />
          </div>
        </Drawer>
        <Box
          sx={{
            width: mobileOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
            ml: mobileOpen ? "auto" : "",
            pl: "25px",
          }}
        >
          <Toolbar />
          {toggleValue !== "growth" && toggleValue !== "category_growth" ? (
            <Box sx={{ display: "flex", width: "100%" }}>
              <FormControlLabel
                control={<Checkbox checked />}
                label="Show Total "
              />
              {toggleValue === "comparision" ? (
                <>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      sx={{ display: "flex", flexDirection: "row", ml: "60px" }}
                      onChange={handleChangeRadioButton}
                    >
                      <FormControlLabel
                        value="Client_1"
                        control={<Radio />}
                        label="Customer 1"
                      />
                      <FormControlLabel
                        value="Client_2"
                        control={<Radio />}
                        label="Customer 2"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Box
                    sx={{
                      width: "15%",
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 1,
                      ml:10
                    }}
                  >
                    <Tooltip
                      placement="right"
                      arrow
                      title={
                        <React.Fragment>
                          <div>1. Select Client 1 or Client 2 .</div>
                          <div>
                            {" "}
                            2. Comparision of total amounts of Selected data
                            will be shown in graph.
                          </div>
                          <div>
                            3.List of categories are shown below the graph.
                          </div>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Box>
                </>
              ) : (
                <ControlledRadioButtonsGroup
                  handleChange={handleChangeRadioButton}
                  value={value}
                />
              )}
            </Box>
          ) : (
            <Box sx={{ display: "flex", width: "100%", pt: 5 }}></Box>
          )}
          {toggleValue === "category_growth" ? (
            <Component5
              handleChangeSelect={(e) => handleChangeSelect(e)}
              onchangeCategoryIdForGrowth={(e) =>
                onchangeCategoryIdForGrowth(e)
              }
              handleChangeForCategoryGrowthYear={(e) =>
                handleChangeForCategoryGrowthYear(e)
              }
              handleChangeForCategoryGrowthMonth={(e) =>
                handleChangeForCategoryGrowthMonth(e)
              }
              graphData={graphData}
              mobileOpen={mobileOpen}
              toggleValue={toggleValue}
              graphCategoryName={graphCategoryName}
              value={value}
              CategoryId={CategoryId}
              val={year}
              holiday={holiday}
              val1={month}
              val2={week}
              empty={false}
              customerId={customerId}
              categories={categories}
              setCategoryId={setCategoryId}
              holidayName={holidayName}
            />
          ) : toggleValue === "growth" ? (
            <Box>
              <Component4
                handleChangeSelect={(e) => handleChangeSelect(e)}
                handleChangeSelectForGrowthYear={(e) =>
                  handleChangeSelectForGrowthYear(e)
                }
                handleChangeSelectForGrowthMonth={(e) =>
                  handleChangeSelectForGrowthMonth(e)
                }
                graphData={graphData}
                setProductData={setProductData}
                mobileOpen={mobileOpen}
                toggleValue={toggleValue}
                graphCategoryName={graphCategoryName}
                value={value}
                handleChangeSelect1={(e) => handleChangeSelect1(e)} // handleChangeSelectForProduct={(e) => handleChangeSelectForProduct(e)}
                handleChangeSelect2={(e) => handleChangeSelect2(e)}
                onchangeCategoryId={(e) => onchangeCategoryId(e)}
                CategoryId={CategoryId}
                val={customerGrowthPayloads.year}
                holiday={holiday}
                val1={customerGrowthPayloads.month}
                val2={week}
                empty={false}
                customerId={customerId}
                holidayIndex={holidayIndex}
                setHolidayIndex={setHolidayIndex}
                setCategoryId={setCategoryId}
                setProductHoliday={setProductHoliday}
                setProductHoliday1={setProductHoliday1}
                setHolidayName={setHolidayName}
                holidayName={holidayName}
                customerGrowthHolidays={customerGrowthHolidays}
              />
            </Box>
          ) : toggleValue === "comparision" ? (
            <Box>
              <Component6
                client1={client1}
                client2={client2}
                customers={customers}
                value={value}
                clientname={clientname}
              />
            </Box>
          ) : (
            <Box>
              <Box sx={{ display: "flex", mb: 2 }}>
                <Component
                  handleChangeSelect={(e) => handleChangeSelect(e)}
                  graphData={graphData}
                  setGraphData={setGraphData}
                  setProductData={setProductData}
                  mobileOpen={mobileOpen}
                  toggleValue={toggleValue}
                  graphCategoryName={graphCategoryName}
                  value={value}
                  handleChangeSelect1={(e) => handleChangeSelect1(e)} // handleChangeSelectForProduct={(e) => handleChangeSelectForProduct(e)}
                  handleChangeSelect2={(e) => handleChangeSelect2(e)}
                  onchangeCategoryId={(e) => onchangeCategoryId(e)}
                  CategoryId={CategoryId}
                  val={year}
                  holiday={holiday}
                  val1={month}
                  val2={week}
                  empty={false}
                  customerId={customerId}
                  holidayIndex={holidayIndex}
                  setHolidayIndex={setHolidayIndex}
                  setCategoryId={setCategoryId}
                  setProductHoliday={setProductHoliday}
                  setProductHoliday1={setProductHoliday1}
                  setHolidayName={setHolidayName}
                  holidayName={holidayName}
                />
                <Component1
                  productName={productName}
                  productData={productData}
                  productQuantity={productQuantity}
                  mobileOpen={mobileOpen}
                  handleSortChangeSelect={handleSortChangeSelect}
                  customerId={customerId}
                  val={sort}
                  productHoliday={productHoliday}
                  toggleValue={toggleValue}
                />
              </Box>
              <Box sx={{ display: "flex", mt: 3 }}>
                <Component2
                  handleChangeSelect={(e) => handleChangeSelect(e)}
                  graphData={graphData1}
                  mobileOpen={mobileOpen}
                  toggleValue={toggleValue}
                  holiday={holiday1}
                  holidayIndex={holidayIndex}
                  setProductData={setProductData}
                  value={value}
                  graphCategoryName={graphCategoryName1}
                  // handleChangeSelectForProduct={(e) => handleChangeSelectForProduct(e)}
                  // onchangeCategoryId={(e) => onchangeCategoryId(e)}
                />
                <Component3
                  productName={productName1}
                  mobileOpen={mobileOpen}
                  productData={productData1}
                  productHoliday={productHoliday1}
                  toggleValue={toggleValue}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Toastify />
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerListingdata: (data, page, perPage) =>
      dispatch(getCustomerListingdata(data, page, perPage)),
    getCustomerCategoryGraphData: (data, id, year, month, week) =>
      dispatch(getCustomerCategoryGraphData(data, id, year, month, week)),
    getIncCustomerCategoryGraphData: (data, id, year, month, week) =>
      dispatch(getIncCustomerCategoryGraphData(data, id, year, month, week)),
    getCustomerProductGraphData: (
      data,
      id,
      customerId,
      year,
      month,
      week,
      sort
    ) =>
      dispatch(
        getCustomerProductGraphData(
          data,
          id,
          customerId,
          year,
          month,
          week,
          sort
        )
      ),
    getIncCustomerProductGraphData: (
      data,
      id,
      customerId,
      year,
      month,
      week,
      sort
    ) =>
      dispatch(
        getIncCustomerProductGraphData(
          data,
          id,
          customerId,
          year,
          month,
          week,
          sort
        )
      ),
    getCustomerOffCategoryData: (data, id, year, month) =>
      dispatch(getCustomerOffCategoryData(data, id, year, month)),
    getCustomerOFFProductGraphData: (
      data,
      id,
      customerId,
      year,
      month,
      week,
      sort,
      holidayName
    ) =>
      dispatch(
        getCustomerOFFProductGraphData(
          data,
          id,
          customerId,
          year,
          month,
          week,
          sort,
          holidayName
        )
      ),
    getCustomerPredictiveOffCategoryData: (
      data,
      id,
      customerId,
      year,
      month,
      week,
      sort,
      holidayName
    ) =>
      dispatch(
        getCustomerPredictiveOffCategoryData(
          data,
          id,
          customerId,
          year,
          month,
          week,
          sort,
          holidayName
        )
      ),
    getCustomerPredictiveOffProductData: (
      data,
      id,
      customerId,
      year,
      month,
      week,
      sort
    ) =>
      dispatch(
        getCustomerPredictiveOffProductData(
          data,
          id,
          customerId,
          year,
          month,
          week,
          sort
        )
      ),
    getCustomerGrowth: (data) => dispatch(getCustomerGrowth(data)),
    filterCustomerGrowth: (data, client_id, year, month) =>
      dispatch(filterCustomerGrowth(data, client_id, year, month)),
    getCustomerGrowthHolidaysData: (data, client_id, year, month) =>
      dispatch(getCustomerGrowthHolidaysData(data, client_id, year, month)),
    filterCustomerGrowthbyMonth: (data, client_id, year) =>
      dispatch(filterCustomerGrowthbyMonth(data, client_id, year)),
    getCategoriesForGrowth: (data, id) =>
      dispatch(getCategoriesForGrowth(data, id)),

    getCategoryGrowth: (data, id, id2) =>
      dispatch(getCategoryGrowth(data, id, id2)),
    getCategoryGrowthByYear: (data, id, id2, year) =>
      dispatch(getCategoryGrowthByYear(data, id, id2, year)),

    getCategoryGrowthByMonth: (data, id, id2, year, month) =>
      dispatch(getCategoryGrowthByMonth(data, id, id2, year, month)),
  };
};

export default connect(null, mapDispatchToProps)(DrawerWithFirstGraph);
