import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

const Component3 = ({ productName, productData, mobileOpen, productHoliday, toggleValue }) => {
  useEffect(() => {
    if (toggleValue === "holiday") {
      productData = Object.values(productHoliday)[0]?.predictive_product_sales;
      productName = Object.values(productHoliday)[0]?.product_name;
    }

    // Create the chart context
    const canvas = document.getElementById("myChart3");
    const ctx = canvas.getContext("2d");
    const plugin2=  {beforeInit: function (chart) {
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;
    
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height as suggested in another answers
        this.height += 15;
      }
    },}
    const yAxisLabelCallback = (value) => {
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + "M€";
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + "k€";
      } else {
        return value;
      }
    };

    const xAxisLabelCallback = (name) => {
      if (name.length > 7) {
        return name.substring(0, 7 - 2) + "..."; // Add ellipsis
      } else return name;
    };

    const emptyFunction = (value1) => {
      return value1;
    };

    const data = {
      labels: productName,
      datasets: [
        {
          label: "Products",
          data: productData,
          backgroundColor: "#FA7070",
        },
      ],
    };

    const options = {
      responsive: true,
      legend: {
        onClick: () => false,
      },
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              callback: productData ? yAxisLabelCallback : emptyFunction,
            },
          },
        ],
        xAxes: [
          {
            display: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 20,
              callback: xAxisLabelCallback,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          align: "end",
          anchor: "end",
          rotation: 0,
          formatter: yAxisLabelCallback,
          font: {
            weight: "bold",
          },
        },
      },
    };

    // Create the bar chart
    const myChart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
      plugins: [ChartDataLabels,plugin2],
    });
    return () => {
      myChart.destroy();
    };
  }, [productName, productData, productHoliday]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: "100%", lg: "35%" },
        border: "2 px solid #7f7f7f",
        ml: 25,
        mt: 4,
      }}>
      <Box
        sx={{
          mt: "74px",
          width: mobileOpen ? "600px" : "650px",
          height: "300px",
        }}>
        <canvas width={"70%"} height={"40%"} id="myChart3"></canvas>
      </Box>
    </Box>
  );
};

export default Component3;
