import { toast } from "react-toastify";
import CustomerApi from "../../services/customers";

export const getCustomerListingdata = (data, page, perPage) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCustomerdata(data, page, perPage);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCustomerData = (data, page, perPage) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCustomerdata(data, page, perPage);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCustomerCategoryGraphData = (data, id, year, month, week) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCategoryGraphData(data, id, year, month, week);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getIncCustomerCategoryGraphData = (data, id, year, month, week) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestgetIncCategoryGraphData(data, id, year, month, week);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCustomerProductGraphData = (data, id, id2, year, month, week, sort) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestProductGraphData(data, id, id2, year, month, week, sort);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getIncCustomerProductGraphData = (data, id, id2, year, month, sort, week) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestIncProductGraphData(data, id, id2, year, month, sort, week);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const getHolidayData = (id, year) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestHolidayData(id, year);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const getCustomersBySearch = (data) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCustomersBySearch(data);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCustomerOffCategoryData = (data, id, year, month) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestHolidayCategoryGraphData(data, id, year, month);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCustomerOFFProductGraphData = (data, id, id2, year, month, week, sort, holidayName) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestHolidayProductGraphData(data, id, id2, year, month, week, sort, holidayName);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCustomerPredictiveOffCategoryData = (data, id, id2, year, month, week, sort) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestHolidayPredictiveGraphData(data, id, id2, year, month, sort);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCustomerPredictiveOffProductData = (data, id, id2, year, month, week, sort, holidayName) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestHolidayPredictiveProductData(data, id, id2, year, month, week, sort, holidayName);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCustomerGrowth = (data) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCustomerGrowth(data);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const filterCustomerGrowth = (data, client_id, year, month) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCustomerGrowthbyYear(data, client_id, year, month);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const filterCustomerGrowthbyMonth = (data, client_id, year) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCustomerGrowthbyYearMonth(data, client_id, year);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const getCustomerGrowthHolidaysData = (data, client_id, year, month) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCustomerGrowthHolidaysData(data, client_id, year, month);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCategoriesForGrowth = (data, id) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCategoryGrowth(data, id);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCategoryGrowth = (data, id, id2) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCategoryAllGrowth(data, id, id2);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCategoryGrowthByYear = (data, id, id2, year) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCategoryYearGrowth(data, id, id2, year);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getCategoryGrowthByMonth = (data, id, id2, year, month) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestCategoryMonthGrowth(data, id, id2, year, month);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getComparisionData = (id, id2) => async (dispatch) => {
  try {
    let response = await CustomerApi.requestComparisionData(id, id2);
    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
