/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  FormLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Table,
  Tooltip,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js";
import CustomSelect from "./CustomSelect";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { getComparisionData } from "../redux/action/customers";
import "./chart.css";
import LoaderComponent from "./LoaderComponent";
const useStyles = makeStyles((theme) => ({
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
const Component6 = ({
  client1,
  client2,
  getComparisionData,
  value,
  clientname,
}) => {
  const classes = useStyles();
  const [graph, setGraph] = useState("");
  const [data, setData] = useState();
  const [load, setLoad] = useState(false);
  const [data1, setData1] = useState();
  useEffect(() => {
    if (client1 && client2) {
      setLoad(true);
      getComparisionData(client1, client2).then((res) => {
        setLoad(false);
        setGraph([
          res.data.total_sales_amount_client1,
          res.data.total_sales_amount_client2,
        ]);
        setData(res.data.client1_categories);
        setData1(res.data.client2_categories);
      });
    }
  }, [client1, client2]);

  useEffect(() => {
    const canvas = document.getElementById("myChart2");
    const ctx = canvas.getContext("2d");
    const plugin2 = {
      beforeInit: function (chart) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Change the height as suggested in another answers
          this.height += 15;
        };
      },
    };
    const xAxisLabelCallback = (name) => {
      if (name.length > 7) {
        return name.substring(0, 7 - 2) + "..."; // Add ellipsis
      } else return name;
    };

    const yAxisLabelCallback = (value) => {
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + "M€";
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + "k€";
      } else {
        return value;
      }
    };

    const emptyFunction = (value1) => {
      return value1;
    };
    console.log(clientname);
    const data = {
      labels: [clientname.client1, clientname.client2],
      datasets: [
        {
          label: "Comparision",
          data: graph,
          backgroundColor: "#3AA6B9",
        },
      ],
    };

    const options = {
      responsive: true,
      legend: {
        // display: false,

        onClick: () => false,
      },
      plugins: {},
      showTooltips: true,
      tooltipEvents: ["mousemove", "touchstart", "touchmove"],
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              callback: graph ? yAxisLabelCallback : emptyFunction,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 20,
              callback: xAxisLabelCallback,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          align: "end",
          anchor: "end",
          rotation: 0,
          formatter: yAxisLabelCallback,
          font: {
            weight: "bold",
          },
        },
      },
    };
    console.log("hii");

    // Create the bar chart
    const myChart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
      plugins: [ChartDataLabels, plugin2],
    });
    return () => {
      myChart.destroy();
    };
  }, [graph]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: "100%", lg: "100%" },
        border: "1 px solid black",
        // ml: "100px",
      }}
    >
      <LoaderComponent open={load} />
      <Box
        sx={{
          mt: 1,
          width: { xs: "100%", sm: "100%", lg: "100%" },
          // height: "400px",
          display: "flex",
          // flexDirection: "column",
        }}
      >
        <Box
          sx={{
            mt: 1,
            width: { xs: "100%", sm: "100%", lg: "50%" },
            // height: "400px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <canvas width={"20%"}  id="myChart2"></canvas>
        </Box>
      
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            padding: 2,
          }}
        >
          Categories List
        </Typography>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableCell
              sx={{
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              {clientname.client1}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              {clientname.client2}
            </TableCell>
          </TableHead>
          <TableBody>
            {data &&
              data1 &&
              data?.map((name, index) => (
                <TableRow>
                  <TableCell>{name}</TableCell>
                  {data1?.map((name1, i) =>
                    index === i ? <TableCell>{name1}</TableCell> : ""
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getComparisionData: (id, id2) => dispatch(getComparisionData(id, id2)),
  };
};
export default connect(null, mapDispatchToProps)(Component6);
