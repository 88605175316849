import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

const Component2 = ({ graphCategoryName, graphData, value, mobileOpen, holiday, holidayIndex, toggleValue, setProductData }) => {
  useEffect(() => {
    if (value === "amount") {
      graphData = graphData?.product_sales_total_predictive;
      setProductData("");
    }
    if (toggleValue === "holiday" && value === "amount") {
      graphData = Object.values(holiday)[holidayIndex]?.product_sales_total_predictive;
      graphCategoryName = Object.values(holiday)[holidayIndex]?.product_category_name;
    }
    if (value === "invoices") {
      graphData = graphData?.product_invoiced_sales_total_predictive;
      setProductData("");
    }
    if (toggleValue === "holiday" && value === "invoices") {
      graphData = Object.values(holiday)[holidayIndex]?.product_invoiced_sales_total_predictive;
      graphCategoryName = Object.values(holiday)[holidayIndex]?.product_category_name;
    }
    // Create the chart context
    const canvas = document.getElementById("myChart2");
    const ctx = canvas.getContext("2d");
    const plugin2=  {beforeInit: function (chart) {
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;
    
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height as suggested in another answers
        this.height += 15;
      }
    },}
    const xAxisLabelCallback = (name) => {
      if (name.length > 7) {
        return name.substring(0, 7 - 2) + "..."; // Add ellipsis
      } else return name;
    };

    const yAxisLabelCallback = (value) => {
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + "M€";
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + "k€";
      } else {
        return value;
      }
    };

    const emptyFunction = (value1) => {
      return value1;
    };

    const data = {
      labels: toggleValue === "holiday" ? graphCategoryName : graphCategoryName?.product_category_name,
      datasets: [
        {
          label: `Category ${value} `,
          data: graphData,
          backgroundColor: "#3AA6B9",
        },
      ],
    };

    const options = {
      responsive: true,
      legend: {
        onClick: () => false,
      },
      showTooltips: true,
      tooltipEvents: ["mousemove", "touchstart", "touchmove"],
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              callback: graphData ? yAxisLabelCallback : emptyFunction,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 20,
              callback: xAxisLabelCallback,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          align: "end",
          anchor: "end",
          rotation: 0,
          formatter: yAxisLabelCallback,
          font: {
            weight: "bold",
          },
        },
      },
    };

    // Create the bar chart
    const myChart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
      plugins: [ChartDataLabels,plugin2],
    });
    return () => {
      myChart.destroy();
    };
  }, [graphData, graphCategoryName, value, holiday, holidayIndex]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: "100%", lg: "35%" },
        border: "1 px solid black",
      }}>
      <Typography sx={{ fontSize: "20px", mt: "50px" }}>Predictive analysis graph</Typography>
      <Box
        sx={{
          mt: 3,
          width: mobileOpen ? "600px" : "650px",
          height: "300px",
        }}>
        <canvas width={"70%"} height={"40%"} id="myChart2"></canvas>
      </Box>
    </Box>
  );
};

export default Component2;
