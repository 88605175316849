import React, { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import { getCustomersBySearch } from "../redux/action/customers";
import { connect } from "react-redux";
import { makeStyles, styled } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      marginLeft: "10px",
    },
  },
}));
const SearchInput = ({ setCustomers, getCustomersBySearch, searched, setSearched, setLoader }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const classes = useStyles();
  const handleChange = (event) => {
    setLoader(true);
    setSearchTerm(event.target.value);
    if (event.target.value === "") {
      setLoader(false);
      setSearched(false);
    } else {
      setSearched(true);
      getCustomersBySearch(event.target.value).then((res) => {
        setLoader(false);
        setCustomers(res.data);
        console.log("loader");
      });
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 999,
        background: "white",
        width: "16.3%",
      }}>
      <TextField
        className={classes.root}
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={handleChange}
        inputProps={{ style: { marginLeft: "10px" } }}
        sx={{
          width: "100%",
          background: "white",
        }}
      />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomersBySearch: (data) => dispatch(getCustomersBySearch(data)),
  };
};

export default connect(null, mapDispatchToProps)(SearchInput);
