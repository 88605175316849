import {
  Box,
  FormControl,
  FormLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Chart, { Tooltip } from "chart.js";
import CustomSelect from "./CustomSelect";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
const Component4 = ({
  graphCategoryName,
  graphData,
  handleChangeSelect,
  handleChangeSelectForGrowthYear,
  handleChangeSelectForGrowthMonth,
  handleChangeSelectForGrowthWeek,
  customerGrowthHolidays,
  onchangeCategoryId,
  CategoryId,
  value,
  val,
  val1,
  val2,
  toggleValue,
  holiday,
  setProductData,
  handleChangeSelect2,
  customerId,
  mobileOpen,
  holidayIndex,
  setHolidayIndex,
  empty,
  setCategoryId,
  setProductHoliday,
  setProductHoliday1,
  setHolidayName,
}) => {
  const classes = useStyles();
  const months = [
    { no: "1", name: "January" },
    { no: "2", name: "february" },
    { no: "3", name: "March" },
    { no: "4", name: "April" },
    { no: "5", name: "May" },
    { no: "6", name: "June" },
    { no: "7", name: "July" },
    { no: "8", name: "August" },
    { no: "9", name: "September" },
    { no: "10", name: "October" },
    { no: "11", name: "November" },
    { no: "12", name: "December" },
  ];
  const yearsSelected = ["2023", "2022", "2021", "2020", "2019", "2018", "2017"];

  useEffect(() => {
    // if (value === "amount") {
    //   graphData = graphData?.product_sales_total_predictive;
    //   setProductData("");
    // }
    // if (toggleValue === "holiday" && value === "amount") {
    //   graphData = Object.values(holiday)[holidayIndex]?.product_sales_total_predictive;
    //   graphCategoryName = Object.values(holiday)[holidayIndex]?.product_category_name;
    // }
    // if (value === "invoices") {
    //   graphData = graphData?.product_invoiced_sales_total_predictive;
    //   setProductData("");
    // }
    // if (toggleValue === "holiday" && value === "invoices") {
    //   graphData = Object.values(holiday)[holidayIndex]?.product_invoiced_sales_total_predictive;
    //   graphCategoryName = Object.values(holiday)[holidayIndex]?.product_category_name;
    // }
    // Create the chart context
    const canvas = document.getElementById("myChart2");
    const ctx = canvas.getContext("2d");
    const plugin2=  {beforeInit: function (chart) {
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;
    
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height as suggested in another answers
        this.height += 15;
      }
    },}
    const xAxisLabelCallback = (name) => {
      if (name.length > 7) {
        return name.substring(0, 7 - 2) + "..."; // Add ellipsis
      } else return name;
    };

    const yAxisLabelCallback = (value) => {
      // if (value >= 1000000) {
      //   return (value / 1000000).toFixed(1) + "M€";
      // } else if (value >= 1000) {
      //   return (value / 1000).toFixed(1) + "k€";
      // } else {
      return value + "%";
      // }
    };

    const emptyFunction = (value1) => {
      return value1;
    };

    const data = {
      labels: graphCategoryName,
      // labels: ["1", "2", "3", "4"],
      datasets: [
        {
          label: "Customer Growth",
          data: graphData,
          // data: [1, 2, -4, 20],
          backgroundColor: "#3AA6B9",
        },
      ],
    };

    const options = {
      responsive: true,
      legend: {
        onClick: () => false,
        // display: false,
      },
      showTooltips: true,
      tooltipEvents: ["mousemove", "touchstart", "touchmove"],
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              callback: graphData ? yAxisLabelCallback : emptyFunction,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 20,
              callback: xAxisLabelCallback,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          align: "end",
          anchor: "end",
          rotation: 0,
          formatter: yAxisLabelCallback,
          font: {
            weight: "bold",
          },
        },
      },
    };

    // Create the bar chart
    const myChart = new Chart(ctx, {
      type: "line",
      data: data,
      options: options,
      plugins: [ChartDataLabels,plugin2],
    });
    return () => {
      myChart.destroy();
    };
  }, [graphData, graphCategoryName, value, holiday, holidayIndex]);
  const numberOfProperties = Object.keys(customerGrowthHolidays).length;
  const holidays = [];
  for (var i = 0; i < numberOfProperties; i++) {
    Object.values(customerGrowthHolidays)[i].holidays.map((item) => {
      holidays.push(item.holiday_name);
    });
  }
  //  Object.values(customerGrowthHolidays)[0]?.holidays[0]?.holiday_name
  const handleChangeSelectHoliday = (e, f) => {
    setCategoryId("initial");
    setHolidayIndex(e.target.value);
    setHolidayName(f.props.children);
    setProductHoliday("");
    setProductHoliday1("");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: "100%", lg: "35%" },
        border: "1 px solid black",
        // ml: "100px",
      }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomSelect
          defaultValue={"Filter by year"}
          menuItemData={yearsSelected}
          initialValue={"Select a year"}
          handleChange={handleChangeSelectForGrowthYear}
          disable={customerId === "" ? true : false}
          mW={140}
          value={val}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}>
          <FormLabel
            sx={{
              pl: 1,
              fontSize: "14px",
            }}>
            Filter by month
          </FormLabel>
          <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
            <Select
              SelectDisplayProps={{
                style: {
                  padding: 5,
                  marginLeft: "10px",
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100px",
                },
              }}
              disabled={customerId === "" ? true : false}
              MenuProps={{ disableScrollLock: true }}
              inputProps={{
                style: {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "12px",
                },
              }}
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                borderBottom: "none",
                color: "#dddddd",
              }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={val1}
              onChange={handleChangeSelectForGrowthMonth}>
              <MenuItem value="initial">
                <em>{`Select a month`} </em>
              </MenuItem>
              {months.map((item, index) => (
                <MenuItem key={index} value={item.no}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* <CustomSelect
          defaultValue={"Filter by week"}
          disable={customerId === "" ? true : false}
          menuItemData={["1", "2", "3", "4", "5"]}
          initialValue={"Select a week"}
          handleChange={handleChangeSelectForGrowthWeek}
          mW={140}
          value={val2}
        /> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}></Box>
      </Box>
      <Box
        sx={{
          mt: 1,
          width: mobileOpen ? "800px" : "900px",
          height: "300px",
          display: "flex",
          flexDirection: "column",
        }}>
        {/* <Typography
          sx={{
            textAlign: "center",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Box sx={{ width: "45px", height: "12px", bgcolor: "#3AA6B9", mr: "10px" }}></Box>
          <Typography sx={{ color: "grey" }}> Customer Growth</Typography>
        </Typography> */}
        <canvas width={"70%"} height={"40%"} id="myChart2"></canvas>
        {val !== "initial" && val1 !== "initial" && (
          <Box
            sx={{
              mt: 1,
              ml: "100px",
            }}>
            {Object.values(customerGrowthHolidays)[0]?.holidays.length !== 0 && (
              <Table sx={{ pl: "20px" }}>
                <TableHead>
                  <TableCell sx={{ minWidth: "100px" }}>Weeks</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "200px",
                    }}>
                    Holidays
                  </TableCell>
                </TableHead>
                <TableBody>
                  {Object.keys(customerGrowthHolidays).map((weeks, index) => (
                    <TableRow sx={{}}>
                      {Object.values(customerGrowthHolidays)[index]?.holidays.length !== 0 && <TableCell>{weeks}</TableCell>}
                      {Object.values(customerGrowthHolidays)[index]?.holidays.length !== 0 && (
                        <TableCell sx={{ textAlign: "center" }}>
                          {Object.values(customerGrowthHolidays)[index]?.holidays.map((item) => (
                            <p>{item.holiday_name}</p>
                          ))}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Component4;
