/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  FormLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Chart, { Tooltip } from "chart.js";
import CustomSelect from "./CustomSelect";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { getCategoriesForGrowth, getCategoryGrowth } from "../redux/action/customers";
import "./chart.css";
const useStyles = makeStyles((theme) => ({
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
const Component5 = ({
  graphData,
  graphCategoryName,
  onchangeCategoryIdForGrowth,
  categories,
  handleChangeForCategoryGrowthYear,
  handleChangeForCategoryGrowthMonth,

  CategoryId,
  value,
  val,
  val1,
  val2,
  holiday,

  customerId,
  mobileOpen,
  holidayIndex,
}) => {
  const classes = useStyles();
  const months = [
    { no: "1", name: "January" },
    { no: "2", name: "February" },
    { no: "3", name: "March" },
    { no: "4", name: "April" },
    { no: "5", name: "May" },
    { no: "6", name: "June" },
    { no: "7", name: "July" },
    { no: "8", name: "August" },
    { no: "9", name: "September" },
    { no: "10", name: "October" },
    { no: "11", name: "November" },
    { no: "12", name: "December" },
  ];
  const yearsSelected = ["2023", "2022", "2021", "2020", "2019"];
  useEffect(() => {
    const canvas = document.getElementById("myChart2");
    const ctx = canvas.getContext("2d");
    const plugin2=  {beforeInit: function (chart) {
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;
    
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height as suggested in another answers
        this.height += 15;
      }
    },}
    const xAxisLabelCallback = (name) => {
      if (name.length > 7) {
        return name.substring(0, 7 - 2) + "..."; // Add ellipsis
      } else return name;
    };

    const yAxisLabelCallback = (value) => {
      // if (value >= 1000000) {
      //   return (value / 1000000).toFixed(1) + "M€";
      // } else if (value >= 1000) {
      //   return (value / 1000).toFixed(1) + "k€";
      // } else {
      return value + "%";
      // }
    };

    const emptyFunction = (value1) => {
      return value1;
    };

    const data = {
      labels: graphCategoryName,
      datasets: [
        {
          label: "Product Category Growth",
          data: graphData,
          backgroundColor: "#3AA6B9",
        },
      ],
    };

    const options = {
      responsive: true,
      legend: {
        // display: false,

        onClick: () => false,
      },
      plugins: {},
      showTooltips: true,
      tooltipEvents: ["mousemove", "touchstart", "touchmove"],
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              callback: graphData ? yAxisLabelCallback : emptyFunction,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 20,
              callback: xAxisLabelCallback,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          align: "end",
          anchor: "end",
          rotation: 0,
          formatter: yAxisLabelCallback,
          font: {
            weight: "bold",
          },
        },
      },
    };

    // Create the bar chart
    const myChart = new Chart(ctx, {
      type: "line",
      data: data,
      options: options,
      plugins: [ChartDataLabels,plugin2],
    });
    return () => {
      myChart.destroy();
    };
  }, [graphData, graphCategoryName, value, holiday, holidayIndex, customerId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: "100%", lg: "35%" },
        border: "1 px solid black",
        // ml: "100px",
      }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}>
          <FormLabel
            sx={{
              pl: 1,
              fontSize: "14px",
            }}>
            {"Filter By Category"}
          </FormLabel>
          <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
            <Select
              SelectDisplayProps={{
                style: {
                  padding: 5,
                  marginLeft: "10px",
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100px",
                },
              }}
              disabled={customerId === "" ? true : false}
              MenuProps={{ disableScrollLock: true }}
              inputProps={{
                style: {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100px",
                },
              }}
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                borderBottom: "none",
                color: "#dddddd",
              }}
              value={CategoryId}
              onChange={onchangeCategoryIdForGrowth}>
              <MenuItem value="initial">
                <em>{`Select a category`} </em>
              </MenuItem>

              {categories &&
                categories?.map((item, index) => (
                  <MenuItem key={index} className={classes.ellipsis} value={item.product_internal_category_id}>
                    {item.product_category_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <CustomSelect
          defaultValue={"Filter by year"}
          menuItemData={yearsSelected}
          initialValue={"Select a year"}
          handleChange={handleChangeForCategoryGrowthYear}
          disable={customerId === "" ? true : false}
          mW={140}
          value={val}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}>
          <FormLabel
            sx={{
              pl: 1,
              fontSize: "14px",
            }}>
            Filter by month
          </FormLabel>
          <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
            <Select
              SelectDisplayProps={{
                style: {
                  padding: 5,
                  marginLeft: "10px",
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100px",
                },
              }}
              disabled={customerId === "" ? true : false}
              MenuProps={{ disableScrollLock: true }}
              inputProps={{
                style: {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "12px",
                },
              }}
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                borderBottom: "none",
                color: "#dddddd",
              }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={val1}
              onChange={handleChangeForCategoryGrowthMonth}>
              <MenuItem value="initial">
                <em>{`Select a month`} </em>
              </MenuItem>
              {months.map((item, index) => (
                <MenuItem key={index} value={item.no}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}></Box>
      </Box>
      <Box
        sx={{
          mt: 1,
          width: mobileOpen ? "800px" : "900px",
          height: "300px",
          display: "flex",
          flexDirection: "column",
        }}>
        {/* <Typography sx={{ textAlign: "center", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ width: "45px", height: "12px", bgcolor: "#3AA6B9", mr: "10px" }}></Box>
          <Typography sx={{ color: "grey" }}> Product Category Growth</Typography>
        </Typography> */}

        <canvas width={"70%"} height={"40%"} id="myChart2"></canvas>
      </Box>
    </Box>
  );
};

export default Component5;
