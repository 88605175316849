/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Chart from "chart.js";
import CustomSelect from "./CustomSelect";
import ChartDataLabels from "chartjs-plugin-datalabels";

const Component1 = ({
  productName,
  productData,
  productQuantity,
  handleSortChangeSelect,
  val,
  toggleValue,
  customerId,
  mobileOpen,
  productHoliday,
}) => {
  useEffect(() => {
    if (toggleValue === "holiday") {
      let nestedObject = productHoliday?.product_data && Object.values(productHoliday?.product_data)[0];
      productData = nestedObject?.product_sales;
      productName = nestedObject?.product_name;
      productQuantity = nestedObject?.quantity;
    }
    console.log(productQuantity);
    // Create the chart context
    const canvas = document.getElementById("myChart1");
    const ctx = canvas.getContext("2d");
    const plugin2 = {
      beforeInit: function (chart) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Change the height as suggested in another answers
          this.height += 15;
        };
      },
    };
    const yAxisLabelCallback = (value) => {
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + "M€";
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + "k€";
      } else {
        return value;
      }
    };

    const xAxisLabelCallback = (name) => {
      if (name?.length > 7) {
        return name.substring(0, 7 - 2) + "..."; // Add ellipsis
      } else return name;
    };

    const emptyFunction = (value1) => {
      return value1;
    };

    // Define the chart data
    const data = {
      labels: productName,
      datasets: [
        {
          label: "Products Amount",
          backgroundColor: "#3AA6B9",
          // borderColor: 'rgba(75, 192, 192, 1)',
          yAxisID: "y-axis-1",
          borderWidth: 1,
          data: productData,
        },
        {
          label: "Product Quantity",
          backgroundColor: "rgba(255, 99, 132, 0.6)",
          borderColor: "rgba(255, 99, 132, 1)",
          yAxisID: "y-axis-2",

          // borderWidth: 1,
          data: productQuantity,
        },
      ],
    };

    // Configure the chart options
    const options = {
      // responsive: true,
      legend: {
        onClick: () => false,
      },
      scales: {
        yAxes: [
          {
            id: "y-axis-1",
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              callback: productData ? yAxisLabelCallback : emptyFunction,
            },
          },
          {
            id: "y-axis-2",
            position: "right",
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              // callback: productData ? yAxisLabelCallback : emptyFunction,
            },
          },
        ],
        xAxes: [
          {
            display: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 20,
              callback: xAxisLabelCallback,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          align: "end",
          anchor: "end",
          rotation: 0,
          formatter: yAxisLabelCallback,
          font: {
            weight: "bold",
          },
        },
      },
      onHover: function (event, chartElements) {},
      hover: false,
    };

    // Create the bar chart
    const myChart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
      plugins: [ChartDataLabels, plugin2],
    });
    return () => {
      myChart.destroy();
    };
  }, [productData, productName, productHoliday]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: "100%", lg: "35%" },
        border: "2 px solid #7f7f7f",
        ml: 25,
      }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", ml: 10 }}>
        <CustomSelect
          mW={125}
          defaultValue={"Sort"}
          initialValue={"Select a value"}
          menuItemData={["asc", "desc"]}
          handleChange={handleSortChangeSelect}
          value={val}
          disable={customerId === "" ? true : false}
        />
      </Box>
      <Box
        sx={{
          mt: 5,
          width: mobileOpen ? "600px" : "650px",
          height: "300px",
          ml: "10px",
        }}>
        <canvas width={"70%"} height={"40%"} id="myChart1"></canvas>
      </Box>
    </Box>
  );
};

export default Component1;
