import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, FormLabel } from "@mui/material";

const CustomSelect = ({
  defaultValue,
  menuItemData,
  initialValue,
  handleChange,
  value,
  mW,
  disable,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormLabel
        sx={{
          pl: 1,
          fontSize: "14px",
        }}
      >
        {defaultValue}
      </FormLabel>

      <FormControl sx={{ m: 1, minWidth: mW }} size="small">
        <Select
          SelectDisplayProps={{
            style: {
              padding: 5,
              marginLeft: "10px",
              fontSize: "12px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100px",
            },
          }}
          disabled={disable}
          MenuProps={{ disableScrollLock: true }}
          inputProps={{
            style: {
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "12px",
            },
          }}
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            borderBottom: "none",
            color: "#dddddd",
            "&.MuiSelect-select": {
              maxWidth: "30px",
              overflow: "hidden",
            },
          }}
          value={value && value}
          onChange={handleChange}
        >
          <MenuItem value="initial">{initialValue}</MenuItem>
          {menuItemData &&
            menuItemData?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default CustomSelect;
